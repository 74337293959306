import { Link } from "gatsby";
import React from "react";
import Page from "./Page";

export default function QuestionsPage() {
  return (
    <Page title="Questions">
      <h1 className="text-3xl">Ask and ye shall be answered</h1>
      <p>
        This section is for questions and answers. As time goes I may add
        categories, subpages, etc, but for now I'm planning to keep it simple.
      </p>
      <p>
        Someting you want to know? Email me at{" "}
        <a href="mailto:matt@mvj.fyi">matt@mvj.fyi</a>.
      </p>
      <dl>
        <dt>
          Why do you support Donald Trump for President of the United States?
        </dt>
        <dd>
          Because he's the first president we've had in a long time who works
          for the American people, not the global corporate interests running
          the neoliberal consensus we existed in during the Bush-Clinton-Obama
          years.
        </dd>
        {/* <dt>What do you mean by the "neoliberal consensus"?</dt>
          <dd>Glad you asked.</dd>
          <dt>Where do you get your news/information?</dt>
          <dd>Mostly YouTube and independent right-leaning media.</dd> */}
      </dl>
    </Page>
  );
}
